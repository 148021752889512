<template>
  <div class="headBarWrap">
    <div class="headBar">
      <div class="nav">
        <div class="nav-content">
          <div class="logo-wrp">
            <img class="logo-icon" @click.stop="goIndex" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/logo.png"/>
            <h1>{{ title }}</h1>
          </div>
          <div class="nav-list">
            <!-- <router-link  class="nav-item" :to="{name: 'homePage'}">首页</router-link>
            <router-link  class="nav-item" :to="{name: 'writeClass'}">写字课</router-link>
            <router-link  class="nav-item" :to="{name: 'dailyPoetry'}">每日古诗文</router-link>
            <router-link  class="nav-item" :to="{name: 'dailyComposition'}">同步写作</router-link>
            <router-link  class="nav-item" :to="{name: 'read'}">经典名著阅读力</router-link>
            <router-link  class="nav-item" :to="{name: 'news'}">媒体报道</router-link> -->
            <div class="nav-item" @click.stop="toRouter('homePage')" :class="{choose:chooseIndex==0}">首页</div>
            <!-- <div class="nav-item"  @click.stop="toRouter('writeClass')" :class="{choose:chooseIndex==1}">写字课</div> -->
            <!-- <div class="nav-item"  @click.stop="toRouter('dailyPoetry')" :class="{choose:chooseIndex==2}">每日古诗文</div> -->
            <!-- <div class="nav-item"  @click.stop="toRouter('dailyComposition')" :class="{choose:chooseIndex==3}">同步写作</div> -->
            <!-- <div class="nav-item"  @click.stop="toRouter('read')" :class="{choose:chooseIndex==4}">经典名著阅读力</div> -->
            <!-- <div class="nav-item"  @click.stop="toRouter('news')" :class="{choose:chooseIndex==5}">媒体报道</div> -->
            <!-- <div class="nav-item" @click.stop="toRouter('music')" :class="{choose:chooseIndex==6}" >轻松音乐</div> -->
            <div class="nav-item" @click.stop="toRouter('teacher')" :class="{choose:chooseIndex==7}" >教师入驻</div>
            <!-- <div class="nav-item" @click.stop="toRouter('modelEssay')" :class="{choose:chooseIndex==8}" >范文库</div> -->
            <div class="nav-item" @click.stop="toRouter('artDownload')" :class="{choose:chooseIndex==9}" >下载客户端</div>
            <!-- <div class="nav-item" @click.stop="toRouter('partner')" :class="{choose:chooseIndex==10}" >商务合作</div> -->
          </div>
          <button class="login-btn" v-if="headImg == null" @click.stop="goLogin">学员登录</button>
          <img :src="headImg" class="headImg" v-if="headImg != null"  @click.stop="goStudy">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headBar",
  data() {
    return {
      headImg: null,
      chooseIndex:0,
      title: '河小象写字'
    };
  },
  mounted(){
    let h = location.hostname;
    h.match(/hexiaoxiang.com/) && (this.title = '河象科技');
    h.match(/hexiaoxiang.com.cn/) && (this.title = '河小象写字');
    h.match(/hxxbaby.com/) && (this.title = '河小象写字');
    h.match(/aaao.ren/) && (this.title = '河小象美术');
    h.match(/aaaj.fit/) && (this.title = '河小象软笔');
    h.match(/aaao.biz/) && (this.title = '河小象硬笔');
    document.title = this.title;

    let key = localStorage.getItem("key");
    let headImg = localStorage.getItem("avatarUrl");
    if (key) {
      this.headImg = headImg || "";
    }
    let router=this.$route.path
    if(router=="/writeClass"){
      this.chooseIndex=1
    }else if(router=="/dailyPoetry"){
      this.chooseIndex=2
    }else if(router=="/dailyComposition"){
      this.chooseIndex=3
    }else if(router=="/read"){
      this.chooseIndex=4
    }else if(router=="/homePage"){
      this.chooseIndex=0
    }else if(router=='/news'){
      this.chooseIndex=5
    }else if(router == '/music'){
      this.chooseIndex =6
    }else if(router == '/teacher'){
      this.chooseIndex=7
    }else if(router == '/modelEssay'){
      this.chooseIndex=8
    }else if(router == '/artDownload'){
      this.chooseIndex=9
    }else if(router == '/partner'){
      this.chooseIndex=10
    }
  },
  methods: {
    goIndex () {
      this.$router.push("/");
      this.chooseIndex=0
    },
    toRouter(router) {
      this.$router.push(router);
      if(router=="writeClass"){
        this.chooseIndex=1
      }else if(router=="dailyPoetry"){
        this.chooseIndex=2
      }else if(router=="dailyComposition"){
        this.chooseIndex=3
      }else if(router=="read"){
        this.chooseIndex=4
      }else if(router=="homePage"){
        this.chooseIndex=0
      }else if(router=="news"){
         this.chooseIndex=5
      }else if(router == 'music'){
        this.chooseIndex=6
      }else if(router == 'teacher'){
        this.chooseIndex=7
      }else if(router == 'modelEssay'){
        this.chooseIndex=8
      }else if(router == 'artDownload'){
        this.chooseIndex=9
      }else if(router == 'partner'){
        this.chooseIndex=10
      }
    },
    goLogin() {
      let key = localStorage.getItem("key") || ""; //有key就等于登录了
      if (key != "") {
        this.$router.push({ path: "classroom" });
      } else {
        // this.$router.push({ path: "login" });
        let routeData = this.$router.resolve({ path: 'login' });
        window.open(routeData.href, '_blank');
      }
    },
       //跳到视频播放
    goStudy() {
      this.$router.push({ path: "/classroom" });
    }

  }
};
</script>
<style scoped>
.headBarWrap {
  height: 80px;
}
.headBar{
  width: 100%;
  min-width: 1120px;
  height: 80px;
  z-index: 600;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow:0px 1px 10px 0px rgba(0,0,0,0.16);
}
.nav {
  background-color: #fff;
  height: 80px;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: center;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 10px;
  box-sizing: border-box;
}
.logo-wrp {
  display: flex;
  align-items: center;
}

.logo-wrp h1 {
  font-size: 26px;
  margin-left: 8px;
}

.logo-icon {
  height: 34px;
  cursor: pointer;
}
.nav-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nav-item {
  margin: 0 22px;
  font-size:20px;
  font-family:MicrosoftYaHei-Bold;
  font-weight:bold;
  color:rgba(68,65,68,1);
  line-height: 80px;
  height: 80px;
  display: block;
  position: relative;
  cursor: pointer;
}
.nav-item:hover {
  color:rgba(255,112,65,1);
}
.login-btn{
  width:100px;
  height:30px;
  border:1px solid rgba(255,112,65,1);
  border-radius:15px;
  font-size:16px;
  font-family:MicrosoftYaHei;
  font-weight:400;
  color:rgba(255,112,65,1);
  cursor: pointer;
  background: transparent;
  outline: none;
}
.headImg{
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.choose{
   color:rgba(255,112,65,1);
}
.choose::after{
   position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  background:rgba(255,112,65,1);
}
.router-link-active{
  color:rgba(255,112,65,1);
}
.router-link-active:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  background:rgba(255,112,65,1);
}
</style>
